import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"

class NotFoundPage extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props

    return (
      <>
        <SEO title="404: Not Found" location={this.props.location} />
        <div className="oneColumn n-text-centered">
          <h1 className="n-text-centered n-vspacing-large">Page not found</h1>
          <p>Sorry about that!</p>
        </div>
      </>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
